// @flow
import * as React from 'react';
import {useAgentCallAgendaSubscription, useSetDontDisturbMutation} from "../../enrollment-types";
import {Box, Switch, FormControlLabel, Typography, Chip} from "@mui/material";
import {useState} from "react";

export function CallAgenda() {
  const [dontDisturb, setDontDisturb] = useState(false);

  const [setDontDisturbMutation] = useSetDontDisturbMutation()
  const {data} = useAgentCallAgendaSubscription({
    onData: res => res.data.data && setDontDisturb(res.data.data.agentCallAgenda.dontDisturb)
  });

  if (!data) return null;

  const {agentCallAgenda: {assignedClientsCallTasks}} = data;

  const handleChange = (value: boolean) => {
    setDontDisturb(value)
    setDontDisturbMutation({variables: {dontDisturb: value} })
  }

  return (
    <Box sx={{display: 'flex', gap: 3, alignItems: 'center'}}>
      {!!assignedClientsCallTasks.length && <Chip label={`Clients to call - ${assignedClientsCallTasks.length}`} color="error" sx={{borderRadius: '8px'}}/>}
      <FormControlLabel control={(
        <Switch checked={dontDisturb}
                color={'error'}
                onChange={(e, checked) => handleChange(checked)} />
      )} label="Do not distrib" />
    </Box>
  );
}