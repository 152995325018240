import {
  Box,
  Card, CircularProgress,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton, ListItemIcon, ListItemText,
  Typography
} from "@mui/material";
import React, {useContext, useState} from "react";
import moment from "moment";
import {
  AgentCalendlyAppointmentsQuery,
  DateRangeInput, useAgentByIdLazyQuery,
  useAgentCalendlyAppointmentsLazyQuery
} from "../../enrollment-types";
import {getUserName} from "../../shared/tools";
import DaterangeFilter from "../../shared/filters/DaterangeFilter";
import _ from "lodash";

type AgentAppointment = AgentCalendlyAppointmentsQuery['agentCalendlyAppointments'][0]

const initialDateRange: DateRangeInput = {from: moment().startOf('month').toISOString(), to: moment().endOf('day').toISOString()}

interface Props {
  open: (agentId: string, dates: DateRangeInput) => void;
}

const AgentAppointmentsDrawerContext = React.createContext<Props>({
  open: () => {},
})

export function withAgentAppointmentsDrawer(WrappedComponent: React.ComponentType) {
  return () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [appointments, setAppointments] = useState<AgentAppointment[]>([]);
    const [dateRange, setDateRange] = useState(initialDateRange);
    const [agentName, setAgentName] = useState('')
    const [getAgent] = useAgentByIdLazyQuery({
      onCompleted: data => setAgentName(getUserName(data.agentById))
    });

    const [getAppointments, {loading, refetch}] = useAgentCalendlyAppointmentsLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: data => setAppointments(_.orderBy(data.agentCalendlyAppointments, 'startTime', 'desc') || []),
      notifyOnNetworkStatusChange: true,
    })

    const onClose = () => {
      setAppointments([]);
      setAgentName('');
      setDateRange(initialDateRange);
      setDrawerOpen(false);
    }

    const open = (agentId: string, dates: DateRangeInput) => {
      setAppointments([]);
      setDrawerOpen(true);
      setAgentName('');
      setDateRange(dates);
      getAppointments({
        variables: {
          agentId,
          from: moment(dates.from).toISOString(),
          to: moment(dates.to).toISOString(),
        }
      })
      getAgent({variables: {id: agentId}})
    }

    const handleDateRangeChange = (value?: DateRangeInput) => {
      setDateRange(value || initialDateRange);
      if (value) {
        refetch({
          from: moment(value.from).toISOString(),
          to: moment(value.to).toISOString(),
        })
      }
    }

    return <AgentAppointmentsDrawerContext.Provider value={{open}}>
      <AgentAppointmentsDrawer open={drawerOpen}
                               onClose={onClose}
                               loading={loading}
                               agentName={agentName}
                               dateRange={dateRange}
                               onDateRangeChange={handleDateRangeChange}
                               appointments={appointments}/>
      <WrappedComponent />
    </AgentAppointmentsDrawerContext.Provider>
  }
}

export const useAgentAppointmentsDrawer = () => {
  return useContext(AgentAppointmentsDrawerContext);
}

interface DrawerProps {
  open: boolean,
  onClose: () => void,
  appointments: AgentAppointment[],
  loading: boolean,
  dateRange: DateRangeInput,
  onDateRangeChange: (dateRange?: DateRangeInput) => void,
  agentName: string,
}

function AgentAppointmentsDrawer(props: DrawerProps) {
  const {open, onClose, appointments, dateRange, onDateRangeChange, loading, agentName} = props

  const onClick = (clientId: string) => {
    window.open(`/client/${clientId}`);
  }

  return <Drawer
    anchor={'right'}
    open={open}
    onClose={onClose}
  >
    <Card sx={{p: 4, width: 594, overflowY: 'auto'}}>
      <Box sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', mb: 3}}>
        <Typography variant={'h3'}>{agentName} appointments</Typography>
        <IconButton size={'small'} onClick={onClose}>
          <img className={'w-32 h-32'} src={'/img/X.svg'}/>
        </IconButton>
      </Box>

      <DaterangeFilter value={dateRange}
                       label={'Date'}
                       onChange={onDateRangeChange} />

      <Divider sx={{my: 3}} />

      {loading && <CircularProgress size={25} />}
      <List
        sx={{ width: '100%' }}
      >
        {appointments.map(item => (
          <ListItemButton sx={{gap: 2}} key={item.url} onClick={() => onClick(item.invitee.clientId)}>
            <ListItemIcon>
              <Typography sx={{color: '#000', fontWeight: 600, borderRight: '1px solid #2e2e2e', pr: 2.5, mr: 1}}>
                {moment(item.startTime).format("L")}
                <Box component={'span'} sx={{display: 'block', color: '#2e2e2e', fontWeight: 400}}>{moment(item.startTime).format("H:mm")}</Box>
              </Typography>
            </ListItemIcon>
            <ListItemText primary={item.invitee.name}
                          secondary={item.invitee.email}/>
          </ListItemButton>
        ))}
      </List>
    </Card>
  </Drawer>
}
